






import { Vue, Component } from "vue-property-decorator";
import SearchPsychologistComponent from "../../../components/SearchPsychologistComponent.vue";

@Component({
  components: {
    SearchPsychologistComponent,
  },
})
export default class Search extends Vue {
}
